import React, { useState } from 'react'
import logo from './images/logo.png'
import './App.css'
import About from './About'
import Canvas from './Canvas'
import Newsletter from './Newsletter'

const ROUTES = {
  ABOUT: 'about',
  CANVAS: 'canvas',
  NEWSLETTER: 'newsletter'
}

function App() {
  const [page, setPage] = useState<string>(getDefaultRoute())

  const goToCanvas = () => {
    setPage(ROUTES.CANVAS)
  }

  return (
    <section className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" height="55px" onClick={() => setPage(ROUTES.NEWSLETTER)} />
        <a href="#about" onClick={() => setPage(ROUTES.ABOUT)}>About</a>
        <a href="#canvas" onClick={() => setPage(ROUTES.CANVAS)}>
          <span className="mobile-only">Canvas</span>
          <span className="desktop-only">Digital Canvas</span>
        </a>
        <a href="https://vincentstudio.bigcartel.com/" target="_blank" rel="noreferrer">Shop</a>
        <a className="desktop-only" href="#newsletter" onClick={() => setPage(ROUTES.NEWSLETTER)}>Newsletter</a>
      </header>
      {page === ROUTES.ABOUT && <About onGoToCanvas={goToCanvas}/>}
      {page === ROUTES.CANVAS && <Canvas />}
      {page === ROUTES.NEWSLETTER && <Newsletter onGoToCanvas={goToCanvas} />}
    </section>
  );
}

function getDefaultRoute(): string {
  const { hash } = window.location;
  if (hash.indexOf(ROUTES.CANVAS) > -1) {
    return ROUTES.CANVAS;
  }
  if (hash.indexOf(ROUTES.ABOUT) > -1) {
    return ROUTES.ABOUT;
  }
  return ROUTES.NEWSLETTER;
}

export default App;
