import React from 'react';
import { TImage } from "./types";

type TGallery = {
  images: TImage[],
  selectedImage: TImage | null,
  onSelect: (image: TImage) => void
}

function Gallery({ images, selectedImage, onSelect }: TGallery): JSX.Element {
  return (
    <section className="Canvas-gallery">
      { images.map(image =>
        selectedImage?.id === image.id ? (
          <section className="Canvas-gallery-layers" key={image.id}>
            <img src={image.thumbnail} className="Canvas-gallery-layer-item active" alt={image.id} />
          </section>
        ) : (
          <img
            onClick={() => onSelect(image)}
            src={image.thumbnail}
            className="Canvas-gallery-item"
            alt={image.id}
            key={image.id}
          />
        )
      )}
    </section>
  )
}

export default Gallery;
