import React, { useState } from 'react';

type Props = {
  onGoToCanvas: () => void
}

function Newsletter({ onGoToCanvas }: Props) {
  const [email, setEmail] = useState<string>('')

  return (
    <section className="About-container">
      <article className="About-part">
        <section className="About-text">
          {/*<section className="About-input-container">
            <input
              type="email"
              value={email}
              onChange={ev => setEmail(ev.currentTarget.value)}
              placeholder="Your@email.com"
              className="About-newsletter-input"
            />
            <button className="About-newsletter-button" onClick={onSubscribe}>
              Sign up
            </button>
          </section>*/}
          {/*<iframe
            src="https://studiovincent.substack.com/embed"
            width="100%"
            height="320"
            id="frame1"
            onLoad={() => console.log('loaded')}
            frameBorder="0"
            scrolling="no"
          >
          </iframe>*/}
          <form
            action="https://studiovincent.substack.com/api/v1/free?nojs=true"
            method="post"
            className="About-input-container"
          >
            <input type="hidden" name="first_url" value="https://studiovincent.substack.com/embed" />
            <input type="hidden" name="first_referrer" />
            <input type="hidden" name="current_url" value="https://studiovincent.substack.com/embed" />
            <input type="hidden" name="current_referrer" value="https://main.d3kc1d48gxagy.amplifyapp.com/" />
            <input type="hidden" name="referral_code" />
            <input type="hidden" name="source" value="embed" />
            <input type="hidden" name="referring_pub_id" />
            <input type="hidden" name="additional_referring_pub_ids" />
            {/*<input placeholder="Your@email.com" className="About-newsletter-input" type="email" name="email" />*/}
            <button type="submit" className="About-newsletter-button">
              Sign up
            </button>
          </form>
          <section>
            <p>
              Hello
            </p>
            <p>
              Subscribe to my newsletter <a href="https://substack.com/@studiostories" target="_blank">Studio stories</a> and get a glimpse inside
              the weird and wonderful places of extraordinary artists,
              from how they organise their space, get out of ruts,
              to what influences their creative momentum and methods and with each
              instalment see an original interpretation of their ideal creative environment.
            </p>
            <p>
              I also make limited edition artwork, illustrated fiction and short stories centred around a <a href="/#canvas" onClick={onGoToCanvas}>speculative fictitious landscape</a> from a birds (Breughel) eye view, exploring themes of creativity and technology.
            </p>
          </section>
        </section>
      </article>
      <section className="About-part">
        <img src="/images/newsletter.jpg" alt="Fred" style={{width: '100%'}} />
      </section>
      <section className="Footer">
        Impressum
      </section>
    </section>
  )
}

export default Newsletter;
