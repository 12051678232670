import React, { useState, useEffect } from 'react';
import MainCanvas from "./MainCanvas";
import { TImage } from "./types";

function Canvas() {
  const [data, setData] = useState<TImage[] | null>(null)

  useEffect(() => {
    (async () => {
      const resp = await fetch('/images.json');
      const imagesData: unknown = await resp.json();
      const d = imagesData as TImage[];
      setData(d)
    })()
  }, [])

  return (
    <section className="Canvas-page">
      { data ? (
        <MainCanvas data={data} />
      ) : (
        <section className="Loader">We are loading your images...</section>
      )}
    </section>
  )
}

export default Canvas;
