import React, {useState, useRef} from 'react';
import Gallery from "./Gallery";
import {TImage} from "./types";
import {calcSizeWithZoom, ZOOM_MULTIPLIER, MAX_ZOOM_LEVEL} from "./calcZoom";

type TMainCanvas = {
  data: TImage[]
}

export default function MainCanvas({ data }: TMainCanvas) {
  const [zoom, setZoom] = useState<number>(0)
  const [selectedImage, selectImage] = useState<TImage | null>(null)
  const canvasRef = useRef<HTMLElement>(null)

  const onImageSelect = (image: TImage): void => {
    selectImage(image);
    const imageDOM = document.querySelector<HTMLImageElement>(`#image_${image.id}`)
      || { offsetLeft: 0, offsetTop: 0 }
    canvasRef.current?.scrollTo({
      top: imageDOM.offsetTop,
      left: imageDOM.offsetLeft,
      behavior: "smooth",
    })
  }

  return (
    <>
      <section className="Canvas" ref={canvasRef}>
        { data.map(d => (
          <img
            id={`image_${d.id}`}
            src={d.url}
            key={d.id}
            width={calcSizeWithZoom(d.width, zoom)}
            height={calcSizeWithZoom(d.height, zoom)}
            alt={d.description}
            className={ selectedImage?.id === d.id ? 'Image-active' : '' }
          />
        ))}
        <section className="Zoom-buttons-container">
          <div
            className="Zoom-button"
            onClick={() => zoom < MAX_ZOOM_LEVEL && setZoom(zoom + ZOOM_MULTIPLIER)}
          >
            +
          </div>
          <div
            className="Zoom-button"
            onClick={() => zoom > -MAX_ZOOM_LEVEL && setZoom(zoom - ZOOM_MULTIPLIER)}
          >
            -
          </div>
        </section>
      </section>
      <Gallery images={data} selectedImage={selectedImage} onSelect={onImageSelect} />
    </>
  )
}