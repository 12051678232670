import React from 'react';

type Props = {
  onGoToCanvas: () => void
}

function About({ onGoToCanvas }: Props) {
  return (
    <section className="About-container">
      <article className="About-part">
        <article className="About-text">
          {/*<section className="About-input-container">
            <input placeholder="Subscribe here" className="About-newsletter-input" />
            <button className="About-newsletter-button">
              Join
            </button>
          </section>*/}
          <form
            action="https://studiovincent.substack.com/api/v1/free?nojs=true"
            method="post"
            className="About-input-container"
            onSubmit={ (e) => e.stopPropagation() }
          >
            <input type="hidden" name="first_url" value="https://studiovincent.substack.com/embed" />
            <input type="hidden" name="first_referrer" />
            <input type="hidden" name="current_url" value="https://studiovincent.substack.com/embed" />
            <input type="hidden" name="current_referrer" value="https://main.d3kc1d48gxagy.amplifyapp.com/" />
            <input type="hidden" name="referral_code" />
            <input type="hidden" name="source" value="embed" />
            <input type="hidden" name="referring_pub_id" />
            <input type="hidden" name="additional_referring_pub_ids" />
            {/*<input placeholder="Subscribe here" className="About-newsletter-input" type="email" name="email" />*/}
            <button type="submit" className="About-newsletter-button">
              Join
            </button>
          </form>
         {/* <iframe
            src="https://studiovincent.substack.com/embed"
            width="100%"
            height="320"
            frameBorder="0"
            scrolling="no"
          >
          </iframe>*/}
          <section>
            <p>
              Hi I'm Fred - I paint, draw, write and am the creative drive behind Studio Vincent.
            </p>
            <p>
              I explore how UX design and our behaviour in both the digital and ‘real’ worlds can be used to create transformative experiences.
              During Covid, I interviewed hundreds of artists and makers, turning interviews into a concise newsletter called{' '}
              <a href="https://substack.com/@studiostories" target="_blank">Studio Stories.</a>
            </p>
            <p>
              Studio stories is a glimpse inside the weird and wonderful places of extraordinary artists,
              how they organise their space, get out of ruts, what influences their creative momentum and methods
              and with each instalment an original interpretation of their ideal creative environment.
            </p>
            <p>
              I also make limited edition <a href="https://vincentstudio.bigcartel.com/" target="_blank">artwork</a>,
              illustrated fiction and short stories centred around a{' '}
              <a href="/#canvas" onClick={onGoToCanvas}>speculative fictitious landscape</a>{' '}
              from a birds (Breughel) eye view, exploring themes of creativity and technology.
            </p>
            <p>
              I'm a fellow of the Royal Society of the Arts and studied painting at the Arts University Bournemouth and
              Turps Banana art school. Subscribe to my newsletter to stay in touch!
            </p>
          </section>
        </article>
      </article>
      <section className="About-part">
        <img src="/images/about_new.jpg" alt="Fred" style={{width: '100%'}} />
      </section>
      <section className="Footer">
        Impressum
      </section>
    </section>
  )
}

export default About;
